import React from 'react';
// import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// import bgImage from '../../assets/images/doctor-talking-with-her-elder-patient.jpg';
import AppLogo from '../AppLogo';

const StyledContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  overflow: auto;
  color: white;
  .image-grid {
    height: 100%;
    width: 100%;
    background-color: ${(props) => props.theme.palette.primary.main};
  }
  @media() {
    flex-direction: column;
    justify-content: center;
    .text-container {
      margin-bottom: 16px;
    }
  }
`;
const StyledCard = styled(Paper)`
  width: calc(100vw - 32px);
  height: calc(100vh - 32px);
  padding-left: ${(props) => props.theme.spacing() * 5}px;
  padding-right: ${(props) => props.theme.spacing() * 5}px;
  padding-top: ${(props) => props.theme.spacing() * 2}px;
  padding-bottom: ${(props) => props.theme.spacing() * 2}px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &.login-card {
    width: 380px;
    height: 400px;
    padding-left: ${(props) => props.theme.spacing() * 3}px;
    padding-right: ${(props) => props.theme.spacing() * 3}px;
    .card-content {
      overflow: hidden;
    }
  }
  .card-heading {
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
    &.basic-reg {
      padding-top: ${(props) => props.theme.spacing() * 1}px;
      padding-bottom: ${(props) => props.theme.spacing() * 1}px;
    }
  }
  .card-content {
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    overflow: auto;
  }
  .MuiCardContent-root:last-child {
    padding: 0;
  }
  .form-container {
    /* max-height: calc(100vh - 400px);
    overflow: hidden;
    overflow-y: scroll; */
  }
  .form-container.basic-reg {
    margin-bottom: ${(props) => props.theme.spacing() * 2}px;
  }
  .form-content {
    height: calc(100vh - 350px);
    overflow: hidden;
    overflow-y: auto;
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #888 #f1f1f1; /* For Firefox */
    &.payment-content {
      height: calc(100vh - 610px);
    }
    &.preference-content {
      height: calc(100vh - 672px);
    }
    /* For WebKit-based browsers (Safari, Chrome) */
    /* Customize the scrollbar */
    ::-webkit-scrollbar {
      width: 10px; /* Adjust the width as desired */
    }

    /* Customize the thumb */
    ::-webkit-scrollbar-thumb {
      background-color: #888; /* Customize the thumb color */
    }

    /* Customize the track */
    ::-webkit-scrollbar-track {
      background-color: #f1f1f1; /* Customize the track color */
    }

    /* Show the scrollbar always */
    ::-webkit-scrollbar-thumb:vertical {
      min-height: 50px; /* Adjust the thumb height as desired */
    }

    /* Show the scrollbar always */
    ::-webkit-scrollbar-thumb:horizontal {
      min-width: 50px; /* Adjust the thumb width as desired */
    }
    @media (max-height: ${(props) => props.theme.breakpoints.values.sm}px) {
      height: auto;
    }
  }
  .stepper-container {
    margin-bottom: ${(props) => props.theme.spacing() * 2}px;
  }
  .stepper-label {
    .MuiStepLabel-label {
      margin: 0;
    }
  }
  .accordion-summary-content {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
  }
  .accordion-summary-info-icon {
    font-size: 20px;
    margin-left: 16px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    margin: 0;
    min-height: 320px;
    height: auto;
  }
`;

export default function AppBasePageCard(props) {
  return (
    <StyledContainer className={props?.className}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className="image-grid"
      >
        <Grid item xs={12}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item md={12}>
              <StyledCard elevation={1} className={props?.cardClassName}>
                <AppLogo width={280} />
                {props.children}
              </StyledCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledContainer>
  );
}

AppBasePageCard.propTypes = {
  // md: PropTypes.number,
  children: PropTypes.node,
  // title: PropTypes.array,
};

AppBasePageCard.defaultProps = {
  // md: 3,
};
