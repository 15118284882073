import React, { useEffect, useMemo } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import format from 'date-fns/format';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Box, Card, CardContent, MenuItem, Select } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import fileDownload from 'js-file-download';
import { AppContainer } from '../../packages/AppContainer';
import AppPageTitle from '../../packages/AppPageTitle';
import { StyledDivider } from '../../packages/AppStyledComponents';
import { AppButton } from '../../packages/AppButton';
import AppTable from '../../packages/AppTable';
import ErrorBoundary from '../../packages/AppErrorBoundary';
// import { getPaymentMethod, payGroup } from '../../modules/Payment/actions';

import {
  getGroupById,
  setSelectedGroup,
  downloadByGroupId,
} from '../../modules/Group/actions';
import { APP_CONSTANTS } from '../../constants';
import BillingInfoForm from '../../components/BillingInfoForm';
import { getGroupBillReport, getPeriods } from '../../modules/Billing/actions';

const AgencyTableProps = {
  tableName: 'agency-list-table',
  size: 'large',
  orderBy: '',
  searchCol: false,
  rowsPerPageOptions: [100, 500, 1000],
  data: [],
  headers: [
    { label: 'ID', key: 'UUID', search: false, sort: false },
    { label: 'name', key: 'name', search: false, sort: false },
  ],
  pagination: true,
};

const todayDate = new Date();
const firstDayOfCurrentMonth = new Date(
  todayDate.getFullYear(),
  todayDate.getMonth(),
  1,
);
const firstDayOfPreviousMonth = new Date(
  todayDate.getFullYear(),
  todayDate.getMonth() - 1,
  1,
);
const lastDayOfPreviousMonth = new Date(firstDayOfCurrentMonth - 1);

export default function PaymentPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const { groupId } = params;
  const group = useSelector((state) => state.Groups?.selectedGroup);
  // const [payment, setPayment] = React.useState({});
  const userType = useSelector((state) => state.User.userType);
  const [fromDate, setFromDate] = React.useState(firstDayOfPreviousMonth);
  const [toDate, setToDate] = React.useState(lastDayOfPreviousMonth);
  const [periodsList, setPeriodsList] = React.useState([]);
  const [selectedPeriod, setSelectedPeriod] = React.useState(null);
  const [agencyTableProps, setAgencyTableProps] = React.useState(
    AgencyTableProps,
  );

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };
  const handleToDateChange = (date) => {
    setToDate(date);
  };

  const downloadFile = () => {
    dispatch(
      downloadByGroupId(
        groupId,
        format(fromDate, 'yyyy-MM-dd'),
        format(toDate, 'yyyy-MM-dd'),
      ),
    ).then((resp) => {
      const blob = new Blob(['\ufeff', resp.data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      let filename = 'agencies_in_group.csv';
      const disposition = resp.headers['content-disposition'];
      if (disposition && disposition.indexOf('inline') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      }
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      // setLeadCount(data);
    });
  };

  const downloadGroupBillReport = () => {
    dispatch(getGroupBillReport(groupId, selectedPeriod)).then((resp) => {
      const { data } = resp;
      if (data) {
        const filteredPeriod =
          periodsList?.filter(
            (period) => period?.billing_period_id === selectedPeriod,
          )?.[0] || {};
        const filename = `${group?.name}_${
          filteredPeriod?.billingPeriod || selectedPeriod
        }.csv`;
        fileDownload(data, filename);
      }
    });
  };

  const getUuidDropdownData = () => {
    const groupAgenciesList = agencyTableProps?.data || [];
    let uuidDropdownData = [];
    if (groupAgenciesList) {
      uuidDropdownData = groupAgenciesList.map((obj) => ({
        title: obj?.name,
        value: obj?.UUID,
      }));
    }
    return uuidDropdownData;
  };

  const getPeriodsList = () => {
    dispatch(getPeriods()).then((resp) => {
      const { data } = resp;
      const manipulatedList = data.reverse();
      setPeriodsList([...manipulatedList]);
      setSelectedPeriod(manipulatedList[0]?.billing_period_id);
    });
  };

  const handelPeriodChange = (event) => {
    const value = event?.target?.value;
    setSelectedPeriod(value);
  };

  useEffect(() => {
    if (groupId) {
      dispatch(getGroupById(groupId)).then((resp) => {
        const { data } = resp;
        dispatch(setSelectedGroup(data));
        setAgencyTableProps((prevState) => ({
          ...prevState,
          data: data?.agencies,
        }));
      });
    }
  }, [groupId]);

  useEffect(() => {
    getPeriodsList();
  }, []);

  return useMemo(
    () => (
      <AppContainer>
        <Grid
          alignItems="flex-start"
          justify="space-between"
          alignContent="stretch"
          spacing={2}
          container
        >
          <Grid item xs={12}>
            <AppPageTitle title="Detail Page">
              <AppButton
                variant="text"
                color="primary"
                label="Back to Groups"
                handleClick={() => {
                  history.push(APP_CONSTANTS.APP_ROUTE_URLS.GROUPS);
                }}
              />
            </AppPageTitle>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
              spacing={2}
            >
              {userType === 'BOOMERS_ADMIN' && (
                <>
                  <Grid item xs={12} md={6}>
                    <Card elevation={0} style={{ height: '100%' }}>
                      <CardContent>
                        <Typography variant="h6">Billing Info</Typography>
                        <StyledDivider marginbottom={2} />
                        <Box mb={2} />
                        <BillingInfoForm
                          uuidList={getUuidDropdownData()}
                        ></BillingInfoForm>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card elevation={0} style={{ height: '100%' }}>
                      <CardContent>
                        <Typography variant="h6">Group Info</Typography>
                        <StyledDivider marginbottom={2} />
                        <Box mb={2} />
                        <Typography gutterBottom>
                          Name: {group?.name}
                        </Typography>
                      </CardContent>
                      <ErrorBoundary>
                        <AppTable {...agencyTableProps} />
                      </ErrorBoundary>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card elevation={0} style={{ height: '100%' }}>
                      <CardContent>
                        <Typography variant="h6">
                          Download Group Info
                        </Typography>
                        <StyledDivider marginbottom={2} />
                        <Box mb={2} />
                        <Box
                          display="flex"
                          align="center"
                          justifyContent="space-between"
                          alignItems="center"
                          mb={2}
                        >
                          <KeyboardDatePicker
                            margin="normal"
                            id="from-date-picker-dialog"
                            label="From"
                            format="MM/dd/yyyy"
                            value={fromDate}
                            onChange={handleFromDateChange}
                            disableFuture
                            KeyboardButtonProps={{
                              'aria-label': 'from change date',
                            }}
                          />
                          <KeyboardDatePicker
                            margin="normal"
                            id="to-date-picker-dialog"
                            label="To"
                            format="MM/dd/yyyy"
                            value={toDate}
                            onChange={handleToDateChange}
                            disableFuture
                            KeyboardButtonProps={{
                              'aria-label': 'to change date',
                            }}
                          />
                        </Box>
                        <Box
                          display="flex"
                          align="center"
                          justifyContent="flex-end"
                          alignItems="center"
                          mb={2}
                        >
                          <AppButton
                            variant="contained"
                            color="primary"
                            label="Download Report"
                            handleClick={() => {
                              downloadFile();
                            }}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card elevation={0} style={{ height: '100%' }}>
                      <CardContent>
                        <Typography variant="h6">
                          Download Group Billing Info
                        </Typography>
                        <StyledDivider marginbottom={2} />
                        <Box mb={2} />
                        <Box
                          display="flex"
                          align="center"
                          justifyContent="space-between"
                          alignItems="center"
                          mb={2}
                        >
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="billingPeriod"
                            value={selectedPeriod}
                            style={{ width: '200px' }}
                            onChange={handelPeriodChange}
                          >
                            {periodsList.map((obj) => (
                              <MenuItem value={obj?.billing_period_id}>
                                {obj?.billingPeriod}
                              </MenuItem>
                            ))}
                          </Select>

                          <AppButton
                            variant="contained"
                            color="primary"
                            label="Download Report"
                            handleClick={() => {
                              downloadGroupBillReport();
                            }}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </>
              )}
              {userType === 'SUPER_ADMIN_2' && (
                <>
                  <Grid item xs={12} md={6}>
                    <Card elevation={0} style={{ height: '100%' }}>
                      <CardContent>
                        <Typography variant="h6">Group Info</Typography>
                        <StyledDivider marginbottom={2} />
                        <Box mb={2} />
                        <Typography gutterBottom>
                          Name: {group?.name}
                        </Typography>
                      </CardContent>
                      <ErrorBoundary>
                        <AppTable {...agencyTableProps} />
                      </ErrorBoundary>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Card elevation={0} style={{ height: '100%' }}>
                      <CardContent>
                        <Typography variant="h6">
                          Download Group Info
                        </Typography>
                        <StyledDivider marginbottom={2} />
                        <Box mb={2} />
                        <Box
                          display="flex"
                          align="center"
                          justifyContent="space-between"
                          alignItems="center"
                          mb={2}
                        >
                          <KeyboardDatePicker
                            margin="normal"
                            id="from-date-picker-dialog"
                            label="From"
                            format="MM/dd/yyyy"
                            value={fromDate}
                            onChange={handleFromDateChange}
                            disableFuture
                            KeyboardButtonProps={{
                              'aria-label': 'from change date',
                            }}
                          />
                          <KeyboardDatePicker
                            margin="normal"
                            id="to-date-picker-dialog"
                            label="To"
                            format="MM/dd/yyyy"
                            value={toDate}
                            onChange={handleToDateChange}
                            disableFuture
                            KeyboardButtonProps={{
                              'aria-label': 'to change date',
                            }}
                          />
                        </Box>
                        <Box
                          display="flex"
                          align="center"
                          justifyContent="flex-end"
                          alignItems="center"
                          mb={2}
                        >
                          <AppButton
                            variant="contained"
                            color="primary"
                            label="Download Report"
                            handleClick={() => {
                              downloadFile();
                            }}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </AppContainer>
    ),
    [group, fromDate, toDate, agencyTableProps, periodsList, selectedPeriod],
  );
}
